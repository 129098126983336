import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

interface AdBlockDetectionResult {
  gtm: boolean;
  ga: boolean;
  mixpanel: boolean;
  appsflyer: boolean;
}

const isGtmLoaded = () => {
  // @ts-expect-error window is extendable
  if (typeof window !== 'undefined' && window.google_tag_manager) {
    // @ts-expect-error window, gtm
    return (window.google_tag_manager[publicRuntimeConfig.gtagAdsId]?.bootstrap || 0) > 0;
  }
  return false;
};

const isGALoaded = () => {
  // @ts-expect-error window
  return typeof window !== 'undefined' && typeof window.ga === 'function';
};

const isMixPanelLoaded = () => {
  // @ts-expect-error window
  return typeof window !== 'undefined' && typeof window.mixpanel === 'object';
};

const isAppsFlyerLoaded = () => {
  // @ts-expect-error window
  return typeof window !== 'undefined' && typeof window.AF === 'object';
};

export const adBlockDetect = (): AdBlockDetectionResult => {
  return {
    gtm: isGtmLoaded(),
    ga: isGALoaded(),
    mixpanel: isMixPanelLoaded(),
    appsflyer: isAppsFlyerLoaded(),
  };
};

export const adBlockDetectString = () => {
  const adBlockDetectResult = adBlockDetect();
  return `gtm:${adBlockDetectResult.gtm},ga:${adBlockDetectResult.ga},mixpanel:${adBlockDetectResult.mixpanel},appsflyer:${adBlockDetectResult.appsflyer}`;
};
