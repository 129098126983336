import styles from './ControlsContent.module.scss';

interface ControlsContainerProps {
  deleteButton: React.ReactNode;
  isEditButtonVisible: boolean;
  editButton: React.ReactNode;
  unavailable: boolean;
  isCounterVisible: boolean;
  counter: React.ReactNode;
  isReplaceableBtnVisible: boolean;
  replaceBtn: React.ReactNode;
  addedLabel?: React.ReactNode;
}

export const ControlsContainer = ({
  unavailable,
  isCounterVisible,
  counter,
  isEditButtonVisible,
  editButton,
  deleteButton,
  isReplaceableBtnVisible,
  replaceBtn,
  addedLabel,
}: ControlsContainerProps) => {
  return (
    <div className={styles.controllers}>
      {!unavailable && addedLabel}
      {isEditButtonVisible && editButton}
      {unavailable ? (
        <>
          {isReplaceableBtnVisible && replaceBtn}
          {deleteButton}
        </>
      ) : (
        <>
          {deleteButton}
          {isCounterVisible && counter}
        </>
      )}
    </div>
  );
};
