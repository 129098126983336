import { Dispatch, SetStateAction, useRef } from 'react';
import cx from 'classnames';
import { Typography } from '@mui/material';
import { useHover } from 'usehooks-ts';
import { TileImage } from '@components/Tiles/components/TileImage/TileImage';
import { TileLabels } from '@components/Tiles/components/TileLabels/TileLabels';
import { Coupon } from '@components/UI';
import { ProductTile as IProductTile } from '@commons/product';
import { TileTagList } from '@components/Tiles/components/TiletagList/TileTagList';
import { GroupScale } from '@components/GroupScale/GroupScale';
import { TileQuantity } from '@components/Tiles/components/TileQuantity/TileQuantity';
import { TilePrice } from '@components/Tiles/components/TilePrice/TilePrice';
import { TileName } from '@components/Tiles/components/TileName/TileName';
import { useLocalization } from '@hooks/useLocalization';
import { useSelectItemEvent } from '@modules/ga/hooks/useSelectItemEvent';
import { SingleStoreCartLine as SingleStoreCartLineType } from '@commons/cart';
import { TrackViewItemList } from '@modules/ga/events/ecommerce/view-item-list/TrackViewItemList';
import { ProductListChannelProvider } from '@modules/ga/context/channel';
import { ProductListLocationProvider, useProductListLocation } from '@modules/ga/context/location';
import { usePageListName } from '@modules/ga/hooks/usePageListName';
import { TileStandingOrderButton } from '@components/Tiles/components/TileStandingOrderButton/TileStandingOrderButton';
import { OrderListQuantitySelector } from '../OrderListQuantitySelector/OrderListQuantitySelector';
import styles from './OrderListProductTile.module.scss';

interface ProductTileProps {
  children?: never;
  className?: string;
  product: IProductTile;
  hideGroupScale?: boolean;
  isShowImage?: boolean;
  isReplaced?: boolean;
  lineToReplace?: string;
  setShouldUpdate?: Dispatch<SetStateAction<boolean>>;
  shouldUpdate?: boolean;
  isReorder?: boolean;
  productCount?: number;
  loading?: boolean;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  onReplace?: (promise: Promise<any>) => void;
  onReplaceCompleted?: (
    selectedProductId: string,
    selectedCartLine?: SingleStoreCartLineType,
  ) => void;
  productCarouselType?: string;
}

export const OrderListProductTile = ({
  className,
  product,
  isShowImage = true,
  hideGroupScale = false,
  isReplaced,
  lineToReplace,
  setShouldUpdate,
  shouldUpdate,
  isReorder,
  loading,
  onReplace,
  onReplaceCompleted,
  productCarouselType,
}: ProductTileProps) => {
  const { t } = useLocalization('productTile');
  const { getLocation } = useProductListLocation();
  const { getListName } = usePageListName();
  const productRef = useRef<HTMLDivElement>(null);
  const isHovered = useHover(productRef);

  const shouldRenderGroupScale = !hideGroupScale && (!!product.groupScale || !!product.coupon);
  const { trackSelectItem } = useSelectItemEvent();

  const handleProductClick = (listName: string) => {
    product && trackSelectItem(product, listName);
  };

  const getChannel = () => {
    if (product.marketingTags.sponsored) {
      return 'rec_criteo';
    }
  };

  return (
    <ProductListChannelProvider channel={getChannel()}>
      <ProductListLocationProvider location={getLocation()}>
        <TrackViewItemList
          product={product}
          listName={getListName(product)}
          productRef={productRef}
        />
        <div className={styles.product_card_wrapper} ref={productRef}>
          <div
            // product_tile_card class is used by GTM
            className={`product_tile_card ${cx(
              styles.product_card,
              className,
              styles.product_hover,
            )}`}
            tabIndex={0}
            role="button"
            // eslint-disable-next-line @typescript-eslint/naming-convention
            {...(product.variantId ? { 'data-variant': product.variantId } : {})}
          >
            <div className={styles.product_card_main_info}>
              <TileLabels
                marketingTags={product.marketingTags}
                preparationTime={product.preparationTime}
              />
              {product.productImage.ref && isShowImage && (
                <TileImage
                  img={{
                    ref: product.productImage.ref,
                    alt: product.productImage.alt ?? t('ariaLabels.tileImage'),
                  }}
                  productUrl={product.productPageUrl}
                  hasWineData={product.hasWineData}
                  onProductClick={handleProductClick}
                  expressEligible={product.featureTags.expressEligible}
                  peakQuality={product.featureTags.topPick}
                />
              )}
              <TileTagList
                expressEligible={product.featureTags.expressEligible}
                peakQuality={product.featureTags.topPick}
              />
              <TileName
                name={product.productName}
                brand={product.brandName}
                url={product.productPageUrl}
                id={product.productId}
                onProductClick={handleProductClick}
              />
              {shouldRenderGroupScale && (
                <div className={styles.product_card_promo} aria-label={t('ariaLabels.groupScales')}>
                  <GroupScale
                    {...product.groupScale}
                    grpPrices={product.grpPrices}
                    id={product.productId}
                  />
                  {product.coupon?.couponId && (
                    <Coupon
                      productId={product.productId}
                      title={product.coupon.description}
                      id={product.coupon.couponId}
                      isActive={product.coupon.isActive}
                      prompt={product.coupon.detailedDescription}
                      expirationDate={product.coupon.expirationDate}
                      fullWidth
                    />
                  )}
                </div>
              )}
            </div>
            <div className={styles.product_card_sub_info} data-testid={t('ariaLabels.productInfo')}>
              <div className={styles.product_card_price_block}>
                {product.price.currency && (
                  <TileQuantity
                    unitPrice={product.unitPrice}
                    unitSize={product.unitSize}
                    currency={product.price.currency}
                    roughPricePerUnit={product.roughPricePerUnit}
                    defaultScaleUnit={product.defaultScaleUnit}
                    pricePerScaleUnit={product.pricePerScaleUnit}
                  />
                )}
                <TilePrice
                  formattedCurrentPrice={product.formattedCurrentPrice}
                  currentPrice={product.price}
                  scaleUnit={product.scaleUnit}
                  prevPrice={product?.wasPrice}
                  minValueToOrder={product.quantity.minQuantity}
                />
                {product.savingString && (
                  <Typography component="div" className={styles.product_card_sub_info_scale_price}>
                    {product.savingString}
                  </Typography>
                )}
              </div>
              <OrderListQuantitySelector
                product={product}
                isReplaced={isReplaced}
                lineToReplace={lineToReplace}
                setShouldUpdate={setShouldUpdate}
                shouldUpdate={shouldUpdate}
                isReorder={isReorder}
                loading={loading}
                onReplace={onReplace}
                onReplaceCompleted={onReplaceCompleted}
                productCarouselType={productCarouselType}
              />
            </div>
          </div>
          <TileStandingOrderButton product={product} hidden={!isHovered} />
        </div>
      </ProductListLocationProvider>
    </ProductListChannelProvider>
  );
};

OrderListProductTile.displayName = 'OrderListProductTile';
