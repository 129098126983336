import { ReactNode, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { MobilePromoModalProvider } from '@context/MobilePromoModalContext/MobilePromoModalProvider';
import { MobilePromoModal } from '@components/Modals/MobilePromoModal/MobilePromoModal';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { useAPC } from '@hooks/promo/useAPC';
import { useMasqueradeBarData } from '@hooks/masquerade/useMasqueradeBarData';
import { ReplaceModalProvider } from '@context/ReplaceContext/ReplaceModalContext';
import { MainLayoutProvider } from '@context/MainLayoutContext';
import { APPLY_COUPON, CLOSE_GROUP_SCALE_MODAL } from '@constants/actions';
import { on } from '@modules/emitter';
import { GET_CART } from '@graphql/cart';
import { CartForCheckoutContextProvider } from '@context/CartForCheckoutContext/CartForCheckoutContext';
import { GlobalAlertContainer } from '@components/GlobalAlertContainer/GlobalAlertContainer';

interface CartLayoutProps {
  children: ReactNode;
}

export const CartLayout = ({ children }: CartLayoutProps) => {
  const { isSmallScreen } = useHeaderContext();
  useAPC();
  const { data: masqueradeBarData } = useMasqueradeBarData();
  const client = useApolloClient();

  useEffect(
    () =>
      on((event) => {
        if (event.type === APPLY_COUPON || event.type === CLOSE_GROUP_SCALE_MODAL) {
          client.refetchQueries({ include: [GET_CART] });
        }
      }),
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <CartForCheckoutContextProvider>
      <MainLayoutProvider>
        {masqueradeBarData.masqueradeModeActive && <GlobalAlertContainer showAlerts={false} />}
        <MobilePromoModalProvider>
          {isSmallScreen && <MobilePromoModal />}
          <ReplaceModalProvider>{children}</ReplaceModalProvider>
        </MobilePromoModalProvider>
      </MainLayoutProvider>
    </CartForCheckoutContextProvider>
  );
};
