export const getSectionTitle = (
  hasCouponError: boolean,
  unavailable: boolean,
  outOfStock: boolean,
  isUnavailableOnPage: boolean,
  isModified: boolean,
  isPreviouslySaved: boolean,
  defaultTitle: string,
): string => {
  if (hasCouponError) return '';
  if (unavailable) return 'unavailableText';
  if (outOfStock) return 'outOfStockText';
  if (isUnavailableOnPage) return 'availableText';
  if (isModified) return 'modifiedText';
  if (isPreviouslySaved) return 'previouslySavedText';

  return defaultTitle || '';
};
