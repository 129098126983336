import { default as dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { convertUTCDateSting } from '@utils/convertUTCDateSting';

type Pattern =
  | 'dddd, MMM D'
  | 'dddd, MMMM D'
  | 'h:mm A'
  | 'dddd,'
  | 'YYYY-MM-DDTHH:mm:ss'
  | 'MMM D YYYY'
  | 'h A'
  | 'M/D/YYYY'
  | 'MMMM D'
  | 'dddd, MM/DD'
  | 'ddd';
dayjs.extend(utc);

export const DATE_FORMAT_ERROR = 'Invalid Date';

export const getDate = (
  dateString: string,
  pattern: Pattern,
  formatter?: (date: string) => string,
) => {
  const utcDate = convertUTCDateSting(dateString);

  const formattedDate = dayjs.utc(utcDate).format(pattern);

  return formatter ? formatter(formattedDate) : formattedDate;
};
