import { ChangeEvent } from 'react';
import cx from 'classnames';
import { pencil as PencilIcon } from '@assets/icons/system';
import { IconButton, Alert, Button } from '@components/UI';
import { SimpleCounter } from '@components/SimpleCounter/SimpleCounter';
import { useLocalization } from '@hooks/useLocalization';
import { useSideBagDrawerOpenState } from '@graphql/variables/sideBagDrawerOpenVar';
import { COMPONENTGROUP_MEAL, PERISHABLE } from '@constants/product';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { Price } from '../Price/Price';
import { AlertType, ControlsBaseProps } from '../../types';
import { DeleteButton } from './DeleteButton';
import { ControlsContainer } from './ControlsContainer';
import styles from './ControlsContent.module.scss';

export interface ControlsProps extends ControlsBaseProps {
  alert: AlertType;
  deleteInProgress: boolean;
  currentQuantity: number;
  handleDelete: () => Promise<void>;
  changeQuantity: (newQuantity: number) => void;
  resetAlert: () => void;
  availableQuantity?: number | null;
}

export const ControlsContent = ({
  quantitySelector: { minQuantity, maxQuantity, quantityIncrement: step },
  currentPrice,
  previousPrice,
  product,
  priceIndicators,
  replaceable = false,
  unavailable = false,
  configurable = false,
  modified = false,
  onReplace,
  onEditModal,
  alert,
  deleteInProgress,
  currentQuantity,
  handleDelete,
  changeQuantity,
  resetAlert,
  setIsCartTileUpdating,
  availableQuantity,
  isFilledBag,
}: ControlsProps) => {
  const { t } = useLocalization('components');
  const { isSmallScreen } = useHeaderContext();
  const modifiedAmount = modified ? currentQuantity : 0;
  const cartlineProductName = product?.brandName
    ? `${product.brandName} ${product.productName}`
    : product?.productName;
  const bundleType = product?.bundle?.type;
  const shouldShowEditButton =
    !unavailable &&
    ((configurable && !bundleType) ||
      bundleType === PERISHABLE ||
      bundleType === COMPONENTGROUP_MEAL);
  const { isSideBagDrawerOpen } = useSideBagDrawerOpenState();
  return (
    <div
      className={cx(styles.controllers_wrapper, {
        [styles.drawer_opened]: isSideBagDrawerOpen,
      })}
    >
      {alert.active && (
        <Alert type="error" className={styles.alert} onClose={resetAlert}>
          {alert.message}
        </Alert>
      )}
      <Price
        priceIndicators={priceIndicators}
        currentPrice={currentPrice}
        previousPrice={previousPrice}
        setIsCartTileUpdating={setIsCartTileUpdating}
      />
      <ControlsContainer
        unavailable={unavailable}
        deleteButton={
          <DeleteButton
            deleteInProgress={deleteInProgress}
            onDelete={handleDelete}
            cartlineProductName={cartlineProductName}
          />
        }
        isEditButtonVisible={shouldShowEditButton}
        addedLabel={
          <>
            {!!modifiedAmount && !isSmallScreen && !isFilledBag && (
              <span className={styles.modified_label}>
                {t('modify-order:added', { number: modifiedAmount })}
              </span>
            )}
          </>
        }
        editButton={
          <IconButton
            data-testid="edit-button"
            data-edit-product={product?.productId}
            size="medium"
            className={styles.button}
            onClick={onEditModal}
            ariaLabel={`${t('cart:edit')} ${cartlineProductName} `}
          >
            <PencilIcon className={styles.icon} />
          </IconButton>
        }
        isCounterVisible={!product?.soldBySalesUnit}
        counter={
          <SimpleCounter
            value={currentQuantity}
            step={step}
            size="medium"
            minValue={minQuantity}
            maxValue={maxQuantity}
            buttonArialLabel={product?.productName}
            onMinusClick={() => changeQuantity(currentQuantity - step)}
            onPlusClick={() => changeQuantity(currentQuantity + step)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => changeQuantity(Number(e.target.value))}
          />
        }
        isReplaceableBtnVisible={replaceable}
        replaceBtn={
          <Button
            variant="outlined"
            className={styles.replace_button}
            onClick={onReplace}
            aria-label={`${t('cart:replace')} ${cartlineProductName}`}
          >
            {availableQuantity
              ? t('cartTile.keepOrReplaceButtonText')
              : t('cartTile.replaceButtonText')}
          </Button>
        }
      />
    </div>
  );
};
