import Image from 'next/image';
import cx from 'classnames';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ProductImage } from '@commons/product';
import { useLocalization } from '@hooks/useLocalization';
import { useSideBagDrawerOpenState } from '@graphql/variables/sideBagDrawerOpenVar';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { TileName } from '../components/TileName/TileName';
import { TileTagList } from '../components/TiletagList/TileTagList';
import { useCartLineControlsState } from '../CartTile/components/Controllers/hooks/useCartLineControlsState';
import styles from './ProductTileLine.module.scss';

export interface ProductTileLineProps {
  productPageUrl: string;
  productName: string;
  img: ProductImage;
  isConfigurableProduct?: boolean;
  brandName?: string | null;
  additionalInfo?: JSX.Element;
  controllers?: JSX.Element;
  isPeakQuality?: boolean;
  isExpressEligible?: boolean;
  className?: string;
  handleProductModal?: () => void;
  onTileClick?: () => void;
  isAvailable?: boolean;
  substituted?: boolean;
  linkTarget?: string;
  discontinuedSoon?: boolean;
  isMasqueradeMode?: boolean;
  isSubstitutedProduct?: boolean;
  defaultQuantity?: number;
  isModifiedItem?: boolean;
  isFilledBag?: boolean;
}

export const ProductTileLine = ({
  productName,
  brandName,
  img,
  additionalInfo,
  controllers,
  isPeakQuality,
  isExpressEligible,
  className,
  isConfigurableProduct,
  handleProductModal,
  onTileClick,
  isAvailable,
  substituted = false,
  linkTarget,
  discontinuedSoon = false,
  isMasqueradeMode = false,
  isSubstitutedProduct,
  defaultQuantity,
  isModifiedItem,
  isFilledBag,
}: ProductTileLineProps) => {
  const availableStyles = cx({ [styles.available]: isAvailable });
  const { t } = useLocalization('product');
  const { isSmallScreen } = useHeaderContext();
  const { currentQuantity } = useCartLineControlsState({
    defaultQuantity: defaultQuantity ?? 0,
  });
  const [modifiedAmount, setModifiedAmount] = useState(defaultQuantity);
  const clickable = !!onTileClick || (!isConfigurableProduct && !!handleProductModal);
  const handleClick = () => {
    onTileClick?.();
    if (!isConfigurableProduct && handleProductModal) {
      handleProductModal();
    }
  };

  const { isSideBagDrawerOpen } = useSideBagDrawerOpenState();

  useEffect(() => {
    setModifiedAmount(currentQuantity);
  }, [currentQuantity]);

  const getTileName = () => {
    const tileName = (
      <TileName
        name={productName}
        brand={brandName}
        linkTarget={linkTarget}
        isUnavailable={isMasqueradeMode && discontinuedSoon}
      />
    );

    if (clickable) {
      return (
        <button
          className={styles.tile_clickable}
          data-testid="cart-tile-button"
          onClick={handleClick}
        >
          {tileName}
        </button>
      );
    }

    return tileName;
  };

  return (
    <div
      className={cx(styles.wrapper, {
        [styles.drawer_opened]: isSideBagDrawerOpen,
        [styles.is_substituted]: isSubstitutedProduct,
        availableStyles,
        className,
      })}
      data-testid="cart-tile-container"
    >
      <div className={styles.tile}>
        <div className={styles.image}>
          {img?.ref && (
            <Image src={img?.ref} alt="" width="60" height="60" data-testid="cart-tile-image" />
          )}
        </div>
        <div className={styles.basic_info}>
          {(isSmallScreen || isFilledBag) && !!modifiedAmount && !!isModifiedItem && (
            <span className={styles.modified_label}>
              {t('modify-order:added', { number: modifiedAmount })}
            </span>
          )}
          <div className={styles.name_and_info}>
            {substituted && (
              <Typography className={styles.tag} variant="caption">
                {t('substitute')}
              </Typography>
            )}
            {(isPeakQuality || isExpressEligible) && (
              <TileTagList peakQuality={isPeakQuality} expressEligible={isExpressEligible} />
            )}
            {getTileName()}
            {additionalInfo}
          </div>
        </div>
      </div>
      {controllers}
    </div>
  );
};
